import VideoPlayer from "./../components/VideoPlayer/VideoPlayer"

import video  from "./../video/About.mp4"
import poster  from "./../video/AboutPoster.png"

function About() {
  return(
    <VideoPlayer 
      className="video"
      src={video}
      type="video/mp4"
      poster={poster}
    />
  );
}
export default About;