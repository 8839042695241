function AboutIcon({ size, className }) {

  return(
    <svg xmlns="http://www.w3.org/2000/svg" 
      className={className}
      width={size} height={size}
      viewBox={`0 0 80 80`}
      fill="none"
    >
      <path fill="currentColor" d="M68.42 45.049v23.414c0 .846-.309 1.578-.927 2.196-.618.617-1.35.926-2.195.926H46.566V52.854H34.08v18.731H15.347c-.846 0-1.577-.309-2.195-.926-.618-.618-.927-1.35-.927-2.196V45.05c0-.033.008-.081.024-.147a.664.664 0 0 0 .025-.146l28.049-23.122L68.37 44.756a.68.68 0 0 1 .05.293Zm10.878-3.366-3.024 3.61c-.26.292-.602.471-1.025.536h-.146c-.423 0-.764-.114-1.024-.341L40.323 17.342 6.566 45.487c-.39.26-.78.374-1.17.341-.423-.065-.765-.244-1.025-.536l-3.024-3.61a1.582 1.582 0 0 1-.342-1.146c.033-.44.212-.789.537-1.05l35.073-29.219C37.655 9.423 38.892 9 40.322 9c1.431 0 2.667.423 3.708 1.268l11.902 9.951v-9.512c0-.455.147-.829.44-1.122.292-.292.666-.439 1.121-.439h9.366c.455 0 .83.147 1.122.44.293.292.44.666.44 1.121V30.61l10.682 8.878c.325.26.504.61.537 1.049.032.439-.082.82-.342 1.146Z"/>
    </svg>
  );

}

export default AboutIcon;