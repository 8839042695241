import { useLocation } from "react-router-dom";

import classes from "./Header.module.scss"

function Header({ children }) {

  const { pathname } = useLocation();

  return(
    <header className={`${classes.wrapper} ${pathname === '/' ? `${classes.wrapper_home}` : ``}`}>
      { children }
    </header>
  );
}
export default Header;