//import { useState } from "react";

import Header from "../Header/Header";
import Main from "../Main/Main";
import NavBar from "../NavBar/NavBar";
//import Background from "../Background/Background";

import classes from "./Layout.module.scss"

function Layout({ children }) {

  //const [isActive, setActive] = useState(false);

  /* const toggleClass = () => {
    setActive(!isActive);
  }; */

  return(
    <div className={classes.layout}>
      <Header>
        <NavBar />
      </Header>
      <Main className={classes.content}>
        { children }
      </Main>
      {/* <Background /> */}
      {/* <div className={isActive ? `${classes.screen} ${classes.screen_active}`: `${classes.screen}`} />
      <button className={classes.screen_button} onClick={toggleClass}>ТЕСТ</button> */}
    </div>
  );
}
export default Layout;