function TailIcon({ size, className }) {

  return(
    <svg xmlns="http://www.w3.org/2000/svg" 
      className={className}
      width={size} height={size}
      viewBox={`0 0 67 64`}
      fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M33.5641 0C28.051 21.6677 17.7175 47.196 0 64C17.4771 55.4682 49.7059 32.8596 67 0H33.5641Z" fill="currentColor"/>
    </svg>
  );

}

export default TailIcon;