import { useRef, useState, useEffect } from "react";
import { IconPause, IconPlay, IconRePlay } from './../Icons';

import classes from "./VideoPlayer.module.scss"

function VideoPlayer({ src, type, className, poster }) {

  const videoRef = useRef(null);
  const [showPlayer, setShowPlayer] = useState(true);
  const [playing, setPlaying] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [videoTime, setVideoTime] = useState(0);
  const [progress, setProgress] = useState(0);
  const autoHidePlayer = 2000;

  const videoHandler = (control) => {
    if (control === "play") {
      videoRef.current.play();
      setPlaying(true);
      setShowPlayer(false);
    } else if (control === "pause") {
      videoRef.current.pause();
      setPlaying(false);
      setShowPlayer(true);
    }
  };

  const replay = () => {
    videoRef.current.currentTime = 0;
    videoRef.current.play();
    setPlaying(true);
    setShowPlayer(false);
  };


  // hide player Toggle
  const playerToggle = (event) => {
    if(event.target === event.currentTarget) {
      
      setShowPlayer(!showPlayer);
      
      /* let autoHide;
      if(playing === true){
        clearTimeout(autoHide);
        autoHide = setTimeout(() => {
          hidePlayer();
        }, autoHidePlayer);
      } else {
        clearTimeout(autoHide);
      } */

    }
  };


  // hide player
  const hidePlayer = () => {
    // console.log('autohidePlayer');
    setShowPlayer(false);
  };


  useEffect(() => {

    // update current Time
    const currentTime = setInterval(() => {
      setVideoTime(videoRef?.current?.duration);
      setCurrentTime(videoRef.current?.currentTime);
      setProgress((videoRef.current?.currentTime / videoRef?.current?.duration) * 100);
    }, 1000);

    // hide player Timer
    const hidePlayerTimer = setTimeout(() => {
      hidePlayer();
    }, autoHidePlayer);

    // clearing interval
    return () => {
      clearInterval(currentTime);
      clearInterval(hidePlayerTimer);
    }

  }, []);

  return (
    <div className={`${classes.container} ${ (showPlayer) ? `${classes.container_showPlayer}` : '' }`}>
      <video
        id="video1"
        ref={videoRef}
        className={classes.player}
        autoPlay
        preload="metadata"
        poster={poster}
      >
        <source 
          src={src}
          type={type}
        />
        Извините, ваш браузер не поддерживает видео.
      </video>
      <div className={classes.controlsContainer}  onClick={playerToggle}>
        <div className={classes.controls}>
          {playing ? (
            <label
              onClick={() => videoHandler("pause")}
              className={classes.controlsIcon}
            >
              <IconPause />
            </label>
          ) : (
            <label
              onClick={() => videoHandler("play")}
              className={classes.controlsIcon}
            >
              <IconPlay />
            </label>
          )}
          <label
            onClick={replay}
            className={classes.controlsIcon_small}
          >
            <IconRePlay />
          </label>
        </div>
      </div>

      <div className={classes.timecontrols}>
        <div className={classes.controlsTime}>
          {Math.floor(currentTime / 60) +
            ":" +
            ("0" + Math.floor(currentTime % 60)).slice(-2)}
        </div>
        <div className={classes.time_progressbarContainer}>
          <div
            style={{ width: `${progress}%` }}
            className={classes.time_progressBar}
          ></div>
        </div>
        <div className={classes.controlsTime}>
          {Math.floor(videoTime / 60) +
            ":" +
            ("0" + Math.floor(videoTime % 60)).slice(-2)}
        </div>
      </div>
    </div>
    
  );

}

export default VideoPlayer;