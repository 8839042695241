// import { useLocation } from "react-router-dom";

import classes from "./Background.module.scss"

function Background({ children, background }) {

  //const { pathname } = useLocation();

  return (
    <div className={`${classes.list} ${background === 'inner' ? `${classes.list_inner}` : background === 'inner_game' ? `${classes.list_inner_game}` : ``}`}>
      <div className={classes.inner_game} />
      <div className={classes.inner} />
      <div className={classes.main} />
    </div>
  );
}
export default Background;