function PauseIcon({ size, className }) {

  return(
    <svg xmlns="http://www.w3.org/2000/svg" 
      className={className}
      width={size} height={size}
      viewBox={`0 0 80 80`}
      fill="none"
    >
      <path fill="currentColor" d="M62.688 19.375v41.25a4.125 4.125 0 0 1-4.126 4.125H48.25a4.125 4.125 0 0 1-4.125-4.125v-41.25a4.125 4.125 0 0 1 4.125-4.125h10.313a4.125 4.125 0 0 1 4.124 4.125ZM31.75 15.25H21.437a4.125 4.125 0 0 0-4.125 4.125v41.25a4.125 4.125 0 0 0 4.125 4.125H31.75a4.125 4.125 0 0 0 4.125-4.125v-41.25a4.125 4.125 0 0 0-4.125-4.125Z"/>
    </svg>
  );

}

export default PauseIcon;