import { useState } from "react";
import Title from "../components/Title/Title"
import { IconHome, IconGame, IconTutorial } from './../components/Icons';
import { NavLink } from "react-router-dom";
import Background from '../components/Background/Background';

import classes from "./Game.module.scss"

import media_1 from "./../media/game/01.webm"
import media_2 from "./../media/game/02.webm"
import media_3 from "./../media/game/03.webm"
import media_4 from "./../media/game/04.webm"
import media_5 from "./../media/game/05.webm"
import media_6 from "./../media/game/06.webm"
import media_7 from "./../media/game/07.webm"

import media_like from "./../media/game/like.webm"
import media_dlike from "./../media/game/dislike.webm"
import media_w1 from "./../video/wolf/w1.webm"
import media_w2 from "./../video/wolf/w2.webm"
import media_w3 from "./../video/wolf/w3.webm"

import audio_bubbles from "./../audio/game/Liquids_Fill_09.wav"
import audio_locked from "./../audio/game/Metallic_Machine_01_lowSound.wav"
import audio_game from "./../audio/game.mp3"

import component_1 from "./../images/game/component-1.png"
import component_2 from "./../images/game/component-2.png"
import component_3 from "./../images/game/component-3.png"
import component_4 from "./../images/game/component-4.png"
import apparatus_1 from "./../images/game/apparatus-1.png"
import apparatus_2 from "./../images/game/apparatus-2.png"
import apparatus_3 from "./../images/game/apparatus-3.png"
import apparatus_4 from "./../images/game/apparatus-4.png"

import foundation_1 from "./../images/game/foundation-1.png"
import foundation_2 from "./../images/game/foundation-2.png"
import foundation_3 from "./../images/game/foundation-3.png"
import foundation_4 from "./../images/game/foundation-4.png"
import apparatus_1_big from "./../images/game/apparatus-1-big.png"
import apparatus_2_big from "./../images/game/apparatus-2-big.png"
import apparatus_3_big from "./../images/game/apparatus-3-big.png"
import apparatus_4_big from "./../images/game/apparatus-4-big.png"
import apparatus_5_big from "./../images/game/apparatus-5-big.png"

import timer from "./../images/game/ic_round-timer.svg"
import temperature from "./../images/game/mdi_temperature.svg"

export const dndAcceptType = "myDND";

function Game() {

  const [stage, setStage] = useState(1);

  const components = [
    {
      text: 'Cтирол',
      img: component_1,
      status: {
        1: true,
        2: false,
      }
    },
    {
      text: 'Бутилакрилат',
      img: component_2,
      status: {
        1: true,
        2: false,
      }
    },
    {
      text: 'Эмульгатор',
      img: component_3,
      status: {
        1: false,
        2: true,
      }
    },
    {
      text: 'Вода',
      img: component_4,
      status: {
        1: false,
        2: true,
      }
    }
  ]

  const apparatus = [
    {
      text: 'Аппарат №3',
      img: apparatus_2,
      status: {
        3: true,
        4: false,
        5: false,
      }
    },
    {
      text: 'Аппарат №4',
      img: apparatus_3,
      status: {
        3: false,
        4: true,
        5: false,
      }
    },
    {
      text: 'Аппарат №1',
      img: apparatus_1,
      status: {
        3: false,
        4: false,
        5: false,
      }
    },
    {
      text: 'Цистерна',
      img: apparatus_4,
      status: {
        3: false,
        4: false,
        5: true,
      }
    }
  ]

  const gameInfo = [
    {
      name: 'Старт',
      text: 'Привет! Меня зовут волк Панкрат.<br>Сегодня я предлагаю тебе поучаствовать<br> в нашем рабочем процессе и приготовить<br>акриловую дисперсию «Акратам».',
      media: {
        file: media_1,
      },
    },
    {
      name: 'Стадия 1/5',
      text: 'Какие компоненты необходимо<br>смешать для получения раствора №1?',
      media: {
        file: media_2,
      },
      text_desc: [
        {
          img: temperature,
          title: 'Температурный<br>режим',
          text: '30-50 С°'
        },
        {
          img: timer,
          title: 'Время<br>приготовления',
          text: '2 часа'
        },
      ],
      apparatus: apparatus_1_big
    },
    {
      name: 'Стадия 2/5',
      text: 'Какие компоненты необходимо<br>смешать для получения раствора №2?',
      media: {
        file: media_3,
      },
      text_desc: [
        {
          img: temperature,
          title: 'Температурный<br>режим',
          text: '40-50 С°'
        },
        {
          img: timer,
          title: 'Время<br>приготовления',
          text: '1 часа'
        },
      ],
      apparatus: apparatus_5_big
    },
    {
      name: 'Стадия 3/5',
      text: 'Куда необходимо перекачать содержимое?',
      media: {
        file: media_4,
      },
      text_desc: [
        {
          img: temperature,
          title: 'Температурный<br>режим',
          text: '90-92 С°'
        },
        {
          img: timer,
          title: 'Время<br>приготовления',
          text: '3-4 часа'
        },
      ],
      apparatus: apparatus_2_big
    },
    {
      name: 'Стадия 4/5',
      text: 'Где продукт проходит нейтрализацию?',
      media: {
        file: media_5,
      },
      text_desc: [
        {
          img: timer,
          title: 'Время<br>приготовления',
          text: '4-5 часов'
        },
      ],
      apparatus: apparatus_3_big
    },
    {
      name: 'Стадия 5/5',
      text: 'Что мы делаем дальше с готовым продуктом?',
      media: {
        file: media_6,
      },
      apparatus: apparatus_4_big
    },
    {
      name: 'Финиш',
      text: 'Поздравляем! Вы успешно прошли<br>наш интерактивный курс !',
      media: {
        file: media_7,
      }
    },
    {
      media: {
        file: media_like
      }
    },
    {
      media: {
        file: media_dlike
      }
    },
    {
      media: {
        file: media_w1
      }
    },
    {
      media: {
        file: media_w2
      }
    },
    {
      media: {
        file: media_w3
      }
    },
  ];

  const linkListItems = [
    {
      name: 'Играть еще раз',
      link: '/game#',
      icon: <IconGame />,
      iconColor: 'color-2'
    },
    {
      name: 'Пройти обучение',
      link: '/tutorial',
      icon: <IconTutorial />,
      iconColor: 'color-3'
    },
    {
      name: 'На главную',
      link: '/',
      icon: <IconHome />,
      iconColor: 'color-4'
    },
  ];

  function playVideo(num) {
    const allVideo = document.querySelectorAll('[data-keygame]');

    for (let video of allVideo) {

      video.currentTime = 0;
      video.pause();
      video.dataset.active = 0;

      if (Number(video.dataset.keygame) === num) {

        for (let item of allVideo) {
          item.currentTime = 0;
          item.pause();
        }

        video.currentTime = 0;
        video.play();
        video.dataset.active = 1;
      }
    }
  }

  // next step
  function NextStep(allAnswers) {
    if (stage >= gameInfo.length) return false;
    setStage(stage + 1);
    playVideo(stage + 1);
    sortAnswers()

    if (stage > 1) {
      allAnswers.forEach(item => item.classList.remove(`${classes.game__item_true}`))
      allAnswers.forEach(item => item.classList.remove(`${classes.game__item_false}`))
      allAnswers.forEach(item => item.classList.remove(`${classes.game__item_help}`))
    }
  }

  // step 1
  function Replay() {
    setStage(1);
    playVideo(1);
  }

  // add all videos
  const mediaList = gameInfo.map((item, index) => (
    <video
      className={`${classes.video}`}
      data-keygame={`${index + 1}`}
      data-active={(stage === index + 1) ? (1) : (0)}
      key={index + 1}
      onLoadedMetadata={event => {
        //console.log("IDS-"+ index, + event.target.duration)
        //console.log(event.target.duration);


        if (Number(stage) === index + 1) {
          //event.target.classList.add("active");
          var promise = event.target.play();
          if (promise !== undefined) {
            promise.then(_ => {
              // Autoplay started!
              event.target.defaultMuted = false;
              event.target.muted = false;
              event.target.currentTime = 0;
              event.target.play();
            }).catch(error => {
              console.log(error)
              // Autoplay was prevented.
              /* event.target.defaultMuted = true;
              event.target.muted = true;
              event.target.currentTime = 0;
              event.target.play(); */
            });
          }

        } else {
          // event.target.classList.remove("active");
        }
      }}
      onEnded={() => {
        if (stage === 1) {
          NextStep()

          setTimeout(() => {
            const mainImg = document.getElementsByClassName(`${classes.mainimg}`)[0];
            const game = document.getElementsByClassName(`${classes.game}`)[0];
            mainImg.classList.add(`${classes.mainimg_active}`)
            game.classList.add(`${classes.game_active}`)
          }, 500)
        } else {
          let videoRand = Math.round(10 + Math.random() * 2)
          playVideo(videoRand)
        }
      }}
    >
      <source src={item.media.file} type="video/webm" />
      Извините, ваш браузер не поддерживает видео.
    </video>
  ))

  function checkAnswer(el) {
    const isTrue = el.dataset['true']
    el.classList.add(`${isTrue === 'true' ? classes.game__item_true : classes.game__item_false}`)
    const allAnswers = document.querySelectorAll(`.${classes.game__item}`);
    const mainImg = document.getElementsByClassName(`${classes.mainimg}`)[0];
    const game = document.getElementsByClassName(`${classes.game}`)[0];

    if (isTrue === 'true') {
      const answersTrue = document.querySelectorAll(`.${classes.game__item_true}`);
      const textDesc = document.getElementsByClassName(`${classes.text_desc}`)[0];
      const apparatus = document.getElementsByClassName(`${classes.mainimg__foundation_apparatus}`)[0]

      // correct answer
      if (answersTrue.length !== 0) {
        el.classList.remove(`${classes.game__item_help}`)
        playVideo(8)
        if (stage < 4) {
          apparatus.classList.add(`${classes.mainimg__foundation_apparatus_bounce}`)
        }
        if (stage < 5) {
          // play bubbles
          setTimeout(() => {
            let audioBubbles = new Audio(audio_bubbles)
            audioBubbles.volume = 0.3
            audioBubbles.play()
            apparatus.classList.remove(`${classes.mainimg__foundation_apparatus_bounce}`)
          }, 1500);
        }
      }

      // next stage 
      if ((answersTrue.length === 2 && stage < 4) || (answersTrue.length === 1 && stage === 4)) {
        textDesc.classList.add(`${classes.text_desc_active}`)
        if (stage === 4) {
          let audioLocked = new Audio(audio_locked)
          audioLocked.play()
          apparatus.classList.remove(`${classes.mainimg__foundation_apparatus_bounce_opacity}`)
          apparatus.classList.remove(`${classes.mainimg__foundation_apparatus_hidden}`)
        }

        const timeoutActive = stage === 4 ? 3000 : 2000
        const timeoutNextStep = stage === 4 ? 4000 : 3000

        setTimeout(() => {
          if (stage >= 3) {
            apparatus.classList.add(`${classes.mainimg__foundation_apparatus_hidden}`)
          }
          mainImg.classList.remove(`${classes.mainimg_active}`)
          textDesc.classList.remove(`${classes.text_desc_active}`)
          game.classList.remove(`${classes.game_active}`)
        }, timeoutActive);

        setTimeout(() => {
          if (stage >= 3) {
            apparatus.classList.add(`${classes.mainimg__foundation_apparatus_bounce_opacity}`)
          }
          NextStep(allAnswers)
          mainImg.classList.add(`${classes.mainimg_active}`)
          game.classList.add(`${classes.game_active}`)
        }, timeoutNextStep);

      }


      // next stage
      if (stage > 4 && stage < 7 && answersTrue.length === 1) {
        textDesc.classList.add(`${classes.text_desc_active}`)
        let audioLocked = new Audio(audio_locked)
        audioLocked.play()
        apparatus.classList.remove(`${classes.mainimg__foundation_apparatus_bounce_opacity}`)
        apparatus.classList.remove(`${classes.mainimg__foundation_apparatus_hidden}`)

        setTimeout(() => {
          apparatus.classList.add(`${classes.mainimg__foundation_apparatus_hidden}`)
          mainImg.classList.remove(`${classes.mainimg_active}`)
          textDesc.classList.remove(`${classes.text_desc_active}`)
          game.classList.remove(`${classes.game_active}`)
        }, 3000);

        setTimeout(() => {
          apparatus.classList.add(`${classes.mainimg__foundation_apparatus_bounce_opacity}`)
          NextStep(allAnswers)
          mainImg.classList.add(`${classes.mainimg_active}`)
          game.classList.add(`${classes.game_active}`)
        }, 4000);
      }

    } else {
      const falseAnswers = document.querySelectorAll(`.${classes.game__item_false}`);
      const trueAnswers = document.querySelectorAll(`[data-true=true]:not(.${classes.game__item_true})`);

      if (falseAnswers.length !== 0 && el.classList.contains(`${classes.game__item_false}`)) {
        playVideo(9)
      }

      if ((falseAnswers.length === 2 && stage < 4) || (falseAnswers.length === 3 && stage >= 4)) {
        trueAnswers.forEach(item => item.classList.add(`${classes.game__item_help}`))
      }
    }
  }

  const onAnswerClick = event => {
    checkAnswer(event.currentTarget)
  }

  const sortAnswers = () => {
    let answ = []
    if (stage < 4) {
      answ = components.sort(() => Math.random() - 0.5);
    } else if (stage >= 4) {
      answ = apparatus.sort(() => Math.random() - 0.5);
    }

    return answ;
  }

  let moving = null;
  const touchEnd = e => {
    e.currentTarget.classList.remove(`${classes.dragging}`);
    document.body.removeChild(moving)
    moving = null
    checkAnswer(e.currentTarget)
  }

  function touchStart(e) {
    moving = moving ? moving : e.currentTarget.cloneNode(true)
    e.currentTarget.classList.add(`${classes.dragging}`);
    if (moving) {
      moving.classList.add(`${classes.cloneTouched}`)
      document.body.appendChild(moving);
      moving.style.left = e.changedTouches[0].clientX - moving.clientWidth / 2 + 'px';
      moving.style.top = e.changedTouches[0].clientY - moving.clientHeight / 2 + 'px';
    }
  }

  function touchmove(e) {
    if (moving) {
      moving.style.left = e.touches[0].clientX - moving.clientWidth / 2 + 'px';
      moving.style.top = e.touches[0].clientY - moving.clientHeight / 2 + 'px';
    }
  }

  const answersList = sortAnswers().map((item, index) => (
    <div
      className={`${classes.game__item}`}
      data-true={item.status[stage - 1]}
      draggable="true"
      key={index}
      onClick={onAnswerClick}
      onTouchEnd={touchEnd}
      onTouchStart={touchStart}
      onTouchMove={touchmove}
    >
      <div className={classes.game__item__img}>
        <img src={item.img} alt="" />
      </div>
      <div className={classes.game__item__name}>
        {item.text}
      </div>
    </div>
  ))

  const textDescList = gameInfo[stage - 1].text_desc?.map((item, index) => (
    <div className={classes.text_desc__item} key={stage + index}>
      <div className={classes.text_desc__item_img}>
        <img src={item.img} alt="" />
      </div>
      <div
        className={classes.text_desc__item_title}
        dangerouslySetInnerHTML={{ __html: item.title }}
      >
      </div>
      <div className={classes.text_desc__item_text}>
        {item.text}
      </div>
    </div>
  ))


  //drag and drop
/*   let sourceContainerid = "";
 */
  // Allow multiple draggable items
/*   if (Number(stage) !== 1) {
    let dragSources = document.querySelectorAll('[draggable="true"]');
    dragSources.forEach(dragSource => {
      dragSource.addEventListener("dragstart", dragStart);
    });

    function dragStart(e) {
      this.classList.add(`${classes.dragging}`);
      sourceContainerid = this.id;
      console.log('dragStart')
    }



    function dropped(e) {
      if (this.id !== sourceContainerid) {
        cancelDefault(e);
        this.classList.remove(`${classes.hover}`);
        console.log('dropped')
      }
    }



    function cancelDefault(e) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }

    function dragOver(e) {
      cancelDefault(e);
      this.classList.add("hover");
      console.log('dragOver')
    }

    function dragLeave(e) {
      this.classList.remove("hover");
      console.log('dragLeave')
    }

    let dropTarget = document.querySelectorAll(
      '[data-role="drag-drop-container"]'
    )[0];

    dropTarget.addEventListener("drop", dropped);
    dropTarget.addEventListener("dragenter", cancelDefault);
    dropTarget.addEventListener("dragover", dragOver);
    dropTarget.addEventListener("dragleave", dragLeave);

  } */


  return (
    <>
      <div className={classes.container}>
        <div>
          {stage < 7 && <div className={`${classes.mainimg} ${stage < 7 && stage > 1 && classes.mainimg_active}`} data-role="drag-drop-container">
            <div className={`${classes.mainimg__foundation} ${classes.mainimg__foundation_shadowfloow}`}>
              <img src={foundation_1} alt="" />
            </div>
            <div className={`${classes.mainimg__foundation}`}>
              <img src={foundation_2} alt="" />
            </div>
            <div className={`${classes.mainimg__foundation} ${classes.mainimg__foundation_shadowinside}`}>
              <img src={foundation_3} alt="" />
            </div>
            <div className={`${classes.mainimg__foundation} ${classes.mainimg__foundation_apparatus} ${stage >= 4 && classes.mainimg__foundation_apparatus_bounce_opacity}`}>
              <img src={gameInfo[stage - 1].apparatus} alt="" />
            </div>
            <div className={`${classes.mainimg__foundation} ${classes.mainimg__foundation_front}`}>
              <img src={foundation_4} alt="" />
            </div>
          </div>
          }

          <div className={`${classes.text_desc}`}>
            {textDescList}
          </div>
        </div>
        {stage === 7 &&
          <div className={classes.linksCol}>
            <div className={classes.links}>
              <div
                className={classes.link}
                onClick={Replay}
              >
                <div className={`${classes.icon}`} data-color={`${linkListItems[0].iconColor}`}>
                  {linkListItems[0].icon}
                </div>
                <div className={classes.name}>
                  {linkListItems[0].name}
                </div>
              </div>
              <NavLink
                to={linkListItems[1]?.link}
                className={classes.link}
              >
                <div className={`${classes.icon}`} data-color={`${linkListItems[1]?.iconColor}`}>
                  {linkListItems[1]?.icon}
                </div>
                <div className={classes.name}>
                  {linkListItems[1]?.name}
                </div>
              </NavLink>
              <NavLink
                to={linkListItems[2]?.link}
                className={classes.link}
              >
                <div className={`${classes.icon}`} data-color={`${linkListItems[2]?.iconColor}`}>
                  {linkListItems[2]?.icon}
                </div>
                <div className={classes.name}>
                  {linkListItems[2]?.name}
                </div>
              </NavLink>
            </div>
          </div>
        }
      </div>
      <audio id="audio_game" loop autoPlay>
        <source src={audio_game} type="audio/mpeg" />
      </audio>
      <Title
        stage={gameInfo[stage - 1].name}
        text={gameInfo[stage - 1].text}
      />
      <div className={classes.video__list}>
        {mediaList}
      </div>
      {stage < 7 && <div className={`${classes.game}`}>
        <div className={classes.game__list}>
          {answersList}
        </div>
        {stage < 7 && stage > 1 &&
          <div className={classes.game__info}>
            {`Выберите ${stage < 4 ? '2 нужных компонента или перетащите их' : 'нужный компонент и перетащите его'} на изображение аппарата`}
          </div>
        }
      </div>
      }
      <Background background={stage === 1 ? 'inner_game' : 'inner'} />
    </>
  );
}
export default Game;
