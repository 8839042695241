function RePlayIcon({ size, className }) {

  return(
    <svg xmlns="http://www.w3.org/2000/svg" 
      className={className}
      width={size} height={size}
      viewBox={`0 0 80 80`}
      fill="none"
    >
      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5" d="M35 27.393a17.145 17.145 0 0 1 5-.753c8.287 0 15 6.793 15 15.18S48.287 57 40 57c-8.287 0-15-6.793-15-15.18M37.855 22l-5 5.813 5.83 4.307"/>
    </svg>
  );

}

export default RePlayIcon;