import { useLocation, NavLink } from "react-router-dom";

import { IconAbout, IconGame, IconLogo, IconTutorial } from './../Icons';

import classes from "./NavBar.module.scss"

function NavBar() {

  const { pathname } = useLocation();

  return(
    <div className={`${classes.wrapper} ${pathname === '/' ? `${classes.wrapper_home}` : ``}`}>
      <div className={classes.left}>
        <NavLink 
          to="/"
          className={classes.logoLink}
        >
          <IconLogo className={classes.logo} />
        </NavLink>
      </div>
      <div className={classes.right}>
        <NavLink 
          to="/about"
          className={({ isActive }) => isActive ? `${classes.link} ${classes.activeLink}` : `${classes.link}` }
        >
          <IconAbout className={classes.icon} size="56" />
          <span className={classes.linkText}>О цехе №19</span>
        </NavLink>
        <NavLink 
          to="/tutorial"
          className={({ isActive }) => isActive ? `${classes.link} ${classes.activeLink}` : `${classes.link}` }
        >
          <IconTutorial className={classes.icon} size="56" />
          <span className={classes.linkText}>Обучение</span>
        </NavLink>
        <NavLink 
          to="/game"
          className={({ isActive }) => isActive ? `${classes.link} ${classes.activeLink}` : `${classes.link}` }
        >
          <IconGame className={classes.icon} size="56" />
          <span className={classes.linkText}>Игровой режим</span>
        </NavLink>
      </div>
      
      
    </div>
  );
}
export default NavBar;