function PlayIcon({ size, className }) {

  return(
    <svg xmlns="http://www.w3.org/2000/svg" 
      className={className}
      width={size} height={size}
      viewBox={`0 0 80 80`}
      fill="none"
    >
      <path fill="currentColor" d="M68.875 40a4.058 4.058 0 0 1-1.96 3.483L29.77 66.207a4.125 4.125 0 0 1-5.706-1.42 4.09 4.09 0 0 1-.564-2.066V17.279a4.09 4.09 0 0 1 2.093-3.563 4.126 4.126 0 0 1 4.177.077l37.146 22.724A4.057 4.057 0 0 1 68.875 40Z"/>
    </svg>
  );

}

export default PlayIcon;