import LinkList from '../components/LinkList/LinkList';
import { IconAbout, IconGame, IconTutorial } from './../components/Icons';
import Background from '../components/Background/Background';

import video from "./../video/StartBig.webm"
import videoPoster from "./../video/StartBigPoster.png"
import classes from "./Home.module.scss"

function Home() {

  const linkListItems = [
    {
      name: 'О цехе №19',
      link: '/about',
      icon: <IconAbout />,
      iconColor: 'color-1'
    },
    {
      name: 'Обучение',
      link: '/tutorial',
      icon: <IconTutorial />,
      iconColor: 'color-3'
    },
    {
      name: 'Игровой режим',
      link: '/game',
      icon: <IconGame />,
      iconColor: 'color-2'
    }
  ];

  return (
    <>
      <div className={classes.container}>
        <div className={classes.videoCol}>
          <video
            className={classes.video}
            playsInline autoPlay muted loop
            preload="metadata"
            poster={videoPoster}
          >
            <source src={video} type="video/webm" />
            Извините, ваш браузер не поддерживает видео
          </video>
        </div>
        <div className={classes.linksCol}>
          <div className={classes.header}>
            Выберите нужный раздел
          </div>
          <LinkList items={linkListItems} />
        </div>
      </div>
      <Background background='main'/>
    </>
  );
}
export default Home;