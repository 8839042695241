import classes from "./Main.module.scss"

function Main({ children }) {

  return(
    <main className={`${classes.content}`}>
      { children }
    </main>
  );
}
export default Main;