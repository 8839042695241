// import { React, useRef, useState, useEffect } from "react";

import { IconTail } from "./../Icons"

import classes from "./Title.module.scss"

function Title({ stage, text }) {

  return (
    <div className={classes.container}>
      <div className={classes.stage}>
        {stage}
      </div>
      <div 
        className={classes.text}
        dangerouslySetInnerHTML={{ __html: text }}
      ></div>
      <div className={classes.tail}>
        <IconTail />
      </div>
    </div>
    
  );

}

export default Title;