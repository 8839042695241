import { NavLink } from "react-router-dom";

import classes from "./LinkList.module.scss"

function LinkList({ items }) {

  return(
    <div className={classes.links}>
      {
        items.map((item, index) => (
          <NavLink 
            key={index}
            to={item?.link}
            className={classes.link}
          >
            <div className={`${classes.icon}`} data-color={`${item?.iconColor}`}>
              {item?.icon}
            </div>
            <div className={classes.name}>
              {item?.name}
            </div>
          </NavLink>
        ))
      }
    </div>
  );
}

export default LinkList;