/* eslint eqeqeq: 0 */

import { useState } from "react";
import Title from "../components/Title/Title"
import { IconRePlay } from './../components/Icons';
import Background from '../components/Background/Background';
import { IconGame, IconTutorial } from './../components/Icons';
import { NavLink } from "react-router-dom";

import video from "./../video/Tutorial.webm"
/* import videoPoster  from "./../video/TutorialPoster.png" */

import media_0 from "./../media/tutorial/00.webm"
import media_1 from "./../media/tutorial/01.webm"
import media_2 from "./../media/tutorial/02.webm"
import media_3 from "./../media/tutorial/03.webm"
import media_4 from "./../media/tutorial/04.webm"
import media_5 from "./../media/tutorial/05.webm"
import media_6 from "./../media/tutorial/06.webm"

import media_w1 from "./../video/wolf/w1.webm"
import media_w2 from "./../video/wolf/w2.webm"
import media_w3 from "./../video/wolf/w3.webm"

import audio_tutorial from "./../audio/tutorial.mp3"

import classes from "./Tutorial.module.scss"


function Tutorial() {

  const [stage, setStage] = useState(1);

  //const allVideo = document.querySelectorAll('[data-key]');
  //console.log(allVideo)
  //const tutorialVideo = document.getElementById('tutorialVideo');

  /* Стадия 1: 00:00 - 00:10
  Стадия 2: 00:10 - 00:21
  Переход к стадии 3: 00:21 - 00:23
  Стадия 3: 00:23 - 00:33
  Стадия 4: 00:33 - 00:43
  Стадия 5: 00:43 - 00:55 */

  const tutorialInfo = [
    {
      name: 'Старт',
      text: 'Привет! Меня зовут волк Панкрат.<br>Давай научимся приготовлению<br>акриловой дисперсии «Акратам».',
      media: {
        file: media_0,
      }
    },
    {
      name: 'Стадия 1/5',
      text: 'Приготовление предэмульсии<br /> мономеров в аппарате №1.<br /> Смешать стирол и бутилакрилат.',
      media: {
        file: media_1,
        videoStart: 0,
        videoEnd: 9.5
      }
    },
    {
      name: 'Стадия 2/5',
      text: 'Приготовление раствора<br /> эмульгатора в аппарате №2.<br /> Подать в аппарат воду и эмульгатор.',
      media: {
        file: media_2,
        videoStart: 9.5,
        videoEnd: 20.5
      }
    },
    {
      name: 'Стадия 3/5',
      text: 'Перекачать содержимое аппаратов<br /> №1 и №2 в аппарат №3, где произойдёт<br />реакция  — превращение.',
      media: {
        file: media_3,
        videoStart: 20.5,
        videoEnd: 31.7
      }
    },
    {
      name: 'Стадия 4/5',
      text: 'После полученный продукт<br /> проходит нейтрализацию в аппарате №4.',
      media: {
        file: media_4,
        videoStart: 31.7,
        videoEnd: 43
      }
    },
    {
      name: 'Стадия 5/5',
      text: 'Затем готовый продукт попадает<br />в ёмкость хранения, откуда разливается<br />в автоцистерны потребителю.',
      media: {
        file: media_5,
        videoStart: 43,
        videoEnd: 55
      }
    },
    {
      name: 'Финиш',
      text: 'Поздравляем! Вы успешно прошли обучающий курс!',
      media: {
        file: media_6,
        videoStart: 43,
        videoEnd: 55
      }
    },
    {
      media: {
        file: media_w1
      }
    },
    {
      media: {
        file: media_w2
      }
    },
    {
      media: {
        file: media_w3
      }
    },

  ];

  const linkListItems = [
    {
      name: 'Играть',
      link: '/game',
      icon: <IconGame />,
      iconColor: 'color-2'
    },
    {
      name: 'Повторить обучение',
      link: '/tutorial',
      icon: <IconTutorial />,
      iconColor: 'color-3'
    },
  ];


  function playVideo(num) {
    const allVideo = document.querySelectorAll('[data-key]');

    for (let video of allVideo) {

      video.currentTime = 0;
      video.pause();
      video.dataset.active = 0;

      if (video.dataset.key == num) {

        for (let item of allVideo) {
          item.currentTime = 0;
          item.pause();
        }

        video.currentTime = 0;
        video.play();
        video.dataset.active = 1;
      }
    }

    //console.log(tutorialInfo[2].media.videoStart)

    if (num < 7 && document.getElementById('tutorialVideo')) {
      document.getElementById('tutorialVideo').currentTime = tutorialInfo[num - 1].media.videoStart;
      document.getElementById('tutorialVideo').play();
    }
  }

  // next step
  function NextStep() {
    if (stage >= tutorialInfo.length) return false;
    setStage(stage + 1);
    playVideo(stage + 1);
  }

  // prev step
  function PrevStep() {
    if (stage <= 1) return false;
    setStage(stage - 1);
    playVideo(stage - 1);
  }

  // step 1
  function Replay() {
    setStage(stage);
    playVideo(stage);
  }

  function ReplayAll() {
    setStage(1);
    playVideo(1);
  }

  // add all videos
  const mediaList = tutorialInfo.map((item, index) => (
    <video
      className={`${classes.video}`}
      data-key={`${index + 1}`}
      data-active={(stage === index + 1) ? (1) : (0)}
      key={index + 1}
      onLoadedMetadata={event => {
        //console.log("IDS-"+ index, + event.target.duration)
        //console.log(event.target.duration);

        if (stage == index + 1) {
          //event.target.classList.add("active");
          var promise = event.target.play();
          if (promise !== undefined) {
            promise.then(_ => {
              // Autoplay started!
              event.target.defaultMuted = false;
              event.target.muted = false;
              event.target.currentTime = 0;
              event.target.play();
            }).catch(error => {
              console.log(error)
              // Autoplay was prevented.
              /* event.target.defaultMuted = true;
              event.target.muted = true;
              event.target.currentTime = 0;
              event.target.play(); */
            });
          }

        } else {
          // event.target.classList.remove("active");
        }
      }}
      onEnded={() => {
          if (stage === 1) {
            NextStep()

            setTimeout(() => {
              const mainvideo = document.getElementsByClassName(`${classes.mainvideo}`)[0];
              const control = document.getElementsByClassName(`${classes.control}`)[0];
              mainvideo.classList.add(`${classes.mainvideo_active}`)
              control.classList.add(`${classes.control_active}`)
            }, 500)
          } else {
            let videoRand = Math.round(8 + Math.random() * 2)
            playVideo(videoRand)
          }
        }
      }
    >
      <source src={item.media.file} type="video/webm" />
      Извините, ваш браузер не поддерживает видео.
    </video >
  ))

  return (
    <>
      <div className={classes.container}>
        {stage < 7 && stage > 1 &&
          <>
            <video
              id="tutorialVideo"
              className={classes.mainvideo}
              preload="metadata"
              //poster={videoPoster}
              autoPlay
              muted
              onTimeUpdate={event => {
                /* console.clear()
                console.log("stage-" + stage + " / " + tutorialInfo[stage-1].media.videoStart + " - " + tutorialInfo[stage-1].media.videoEnd);
                console.log(event.target.currentTime); */
                if (
                  event.target.currentTime > tutorialInfo[stage - 1].media.videoEnd ||
                  event.target.currentTime < tutorialInfo[stage - 1].media.videoStart
                ) {
                  event.target.pause();
                }
              }}
            >
              <source
                src={video}
                type="video/webm"
              />
              Извините, ваш браузер не поддерживает видео.
            </video>
            <div className={classes.control}>
              <label
                /* className={(stage > 1) ? `${classes.replay}` : `${classes.replay} ${classes.disable}`} */
                className={`${classes.replay}`}
                onClick={Replay}
              >
                <IconRePlay />
                Повторить
              </label>
              <label
                className={(stage > 1) ? `${classes.back}` : `${classes.back} ${classes.disable}`}
                onClick={PrevStep}>
                Назад
              </label>
              <label
                className={(stage !== tutorialInfo.length) ? `${classes.next}` : `${classes.next} ${classes.disable}`}
                onClick={NextStep}>
                {stage === 6 ? 'Финиш' : 'Далее'}
              </label>
            </div>
          </>
        }
        {stage === 7 &&
          <div className={classes.linksCol}>
            <div className={classes.links}>
              <NavLink
                to={linkListItems[0]?.link}
                className={classes.link}
              >
                <div className={`${classes.icon}`} data-color={`${linkListItems[0]?.iconColor}`}>
                  {linkListItems[0]?.icon}
                </div>
                <div className={classes.name}>
                  {linkListItems[0]?.name}
                </div>
              </NavLink>
              <div
                className={classes.link}
                onClick={ReplayAll}
              >
                <div className={`${classes.icon}`} data-color={`${linkListItems[1].iconColor}`}>
                  {linkListItems[1].icon}
                </div>
                <div className={classes.name}>
                  {linkListItems[1].name}
                </div>
              </div>
            </div>
          </div>
        }
      </div>
      <audio id="audio_tutorial" loop autoPlay>
        <source src={audio_tutorial} type="audio/mpeg" />
      </audio>
      <Title
        stage={tutorialInfo[stage - 1].name}
        text={tutorialInfo[stage - 1].text}
      />
      <div className={classes.video__list}>
        {mediaList}
      </div>
      <Background background={stage === 1 ? 'inner_game' : 'inner'} />
    </>
  );
}

export default Tutorial;