function TutorialIcon({ size, className }) {

  return(
    <svg xmlns="http://www.w3.org/2000/svg" 
      className={className}
      width={size} height={size}
      viewBox={`0 0 80 80`}
      fill="none"
    >
      <path fill="currentColor" d="M46.33 32.665V27a29.273 29.273 0 0 1 5.627-1.75 30.34 30.34 0 0 1 6.04-.583c1.444 0 2.86.111 4.25.333 1.388.223 2.75.5 4.082.834v5.333a23.78 23.78 0 0 0-4.043-1.127 23.444 23.444 0 0 0-4.29-.373c-2.11 0-4.138.264-6.082.793a27.048 27.048 0 0 0-5.583 2.206Zm0 18.333V45.33a29.273 29.273 0 0 1 5.627-1.75 30.34 30.34 0 0 1 6.04-.583c1.444 0 2.86.111 4.25.333 1.388.223 2.75.5 4.082.834v5.333a23.78 23.78 0 0 0-4.043-1.127 23.444 23.444 0 0 0-4.29-.373c-2.11 0-4.138.25-6.082.75-1.945.5-3.806 1.25-5.583 2.25Zm0-9.166v-5.667a29.273 29.273 0 0 1 5.627-1.75 30.34 30.34 0 0 1 6.04-.583c1.444 0 2.86.111 4.25.333 1.388.223 2.75.5 4.082.834v5.333a23.78 23.78 0 0 0-4.043-1.127 23.444 23.444 0 0 0-4.29-.373c-2.11 0-4.138.264-6.082.793a27.048 27.048 0 0 0-5.583 2.206Zm-3.332 14.665c2.444-1.166 4.901-2.042 7.372-2.626a32.9 32.9 0 0 1 7.627-.873c2 0 3.958.166 5.876.5 1.917.333 3.847.833 5.79 1.5V21.998a28.87 28.87 0 0 0-5.71-1.75 30.581 30.581 0 0 0-13.706.417 28.547 28.547 0 0 0-7.25 3v32.831Zm-3.334 9.833a31.99 31.99 0 0 0-8.666-4.916 27.308 27.308 0 0 0-9.666-1.75c-2.333 0-4.625.305-6.876.916A30.08 30.08 0 0 0 8 63.164c-1.167.61-2.291.583-3.373-.084-1.083-.666-1.625-1.639-1.627-2.916V20c0-.611.153-1.195.46-1.75A2.887 2.887 0 0 1 4.833 17a41.284 41.284 0 0 1 8.043-2.96A34.259 34.259 0 0 1 21.332 13c3.222 0 6.375.417 9.46 1.25A35.87 35.87 0 0 1 39.664 18a35.924 35.924 0 0 1 8.877-3.75A36.099 36.099 0 0 1 57.996 13c2.888 0 5.708.348 8.459 1.043A41.322 41.322 0 0 1 74.496 17c.61.277 1.07.694 1.376 1.25.307.555.459 1.139.457 1.75v40.164c0 1.277-.541 2.25-1.623 2.916-1.083.667-2.208.695-3.377.084a30.17 30.17 0 0 0-6.456-2.584 26.106 26.106 0 0 0-6.877-.916c-3.333 0-6.555.583-9.666 1.75a31.99 31.99 0 0 0-8.666 4.916Z"/>
    </svg>
  );

}

export default TutorialIcon;