function AboutIcon({ size, className }) {

  return(
    <svg xmlns="http://www.w3.org/2000/svg" 
      className={className}
      width={size} height={size}
      viewBox={`0 0 80 80`}
      fill="none"
    >
      <path fill="currentColor" d="M72.59 64.977a2.523 2.523 0 0 1-2.522 2.523H9.523a2.523 2.523 0 0 1 0-5.045h60.545a2.523 2.523 0 0 1 2.523 2.522Zm0-47.931v35.318a5.045 5.045 0 0 1-5.044 5.045h-55.5A5.046 5.046 0 0 1 7 52.364V17.045A5.045 5.045 0 0 1 12.046 12h55.5a5.045 5.045 0 0 1 5.045 5.046ZM51.149 34.705a2.523 2.523 0 0 0-1.17-2.129l-13.875-8.83a2.524 2.524 0 0 0-3.876 2.129v17.66a2.523 2.523 0 0 0 3.876 2.128l13.875-8.83a2.522 2.522 0 0 0 1.17-2.128Z"/>
    </svg>
  );

}

export default AboutIcon;